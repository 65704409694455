import React from 'react'

import '../css/Home.css';
import Canvas from '../components/stars';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin  } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Home = () => {
    return (
        <div className="Homepage">
            <Canvas />
            <div className="center" id="content">
                <img src="headshot.jpg" alt="Me in the woods" />
                <div className="selfDescriptionBox">
                    <h1>Colton Kammes</h1>
                </div>
                <div className="socialsBox">
                    <ul class="icons">
                        <li>
                            <a href="https://github.com/Colton-K">
                                <FontAwesomeIcon id="icon" icon={faGithub} size="2x" />
                            </a>
                        </li>
                        <li>
                            <a href="mailto:colton.jumps222@gmail.com">
                                <FontAwesomeIcon id="icon" icon={faEnvelope} size="2x" />
                            </a>
                        </li>
                        <li>
                            <a href="https://linkedin.com/in/colton-kammes-8594a4172/">
                                <FontAwesomeIcon id="icon" icon={faLinkedin} size="2x" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Home;
